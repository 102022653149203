import React, { useState, useEffect } from "react";
import { makeRequest } from "../../../assets/functions_helper";
import {
  Box,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { CloseButton, SendButton } from "../../../components/buttons";
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from "../../../components/customDialog";
import SectionsMenuBuild from "./menu_components/sections_menu_build";
import CreateMenu from "./menu_components/create_menu";
import CreateSection from "./menu_components/create_section";
import AddIcon from "@mui/icons-material/Add";
import menuIcon from "../../../assets/images/icons/menu.png";

// Componente para mostrar el listado de menús
const MenuList = ({ listOfMenus, showMenu }) => (
  <Box display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
    {listOfMenus.map((menu, index) => (
      <Card
        key={index}
        sx={{
          width: 345,
          padding: "20px",
          borderRadius: "25px",
          "&:hover": {
            cursor: "pointer",
            background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
            transform: "scale(1.05)",
            transition: "background 0.5s ease, transform 0.5s ease",
          },
        }}
        onClick={() => showMenu(menu.id)}
      >
        <CardMedia image={menuIcon} sx={{ height: 300 }} />
        <CardContent>
          <Typography gutterBottom variant="h5">
            {menu.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {menu.description}
          </Typography>
        </CardContent>
      </Card>
    ))}
  </Box>
);

// Componente que maneja el menú completo
function MenuRestaurant({ restaurantId }) {
  // Estado general del componente
  const [listOfMenus, setListOfMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMenuDialog, setOpenMenuDialog] = useState(false);
  const [openSectionDialog, setOpenSectionDialog] = useState(false);

  // Estados relacionados con la creación de menús y secciones
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameSection, setNameSection] = useState("");

  const [sectionMenu, setSectionMenu] = useState(false);
  const [sectionProducts, setSectionProducts] = useState(false);
  const [loadingSectionMenu, setLoadingSectionMenu] = useState(false);
  const [loadingSectionProducts, setLoadingSectionProducts] = useState(false);
  const [sections, setSections] = useState([]);
  const [menuId, setMenuId] = useState(0);

  // Cargar menús al montar el componente
  useEffect(() => {
    setSectionProducts(false)
    setLoadingSectionProducts(false)
    makeRequest(
      "GET",
      "menu_manager/view",
      null,
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setListOfMenus(response);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error al obtener los menús:", error);
        setLoading(false);
      });
  }, []);

  // Crear un nuevo menú
  const createMenu = () => {
    setLoading(true);
    makeRequest(
      "POST",
      "menu_manager/create_menu",
      { restaurant_id: restaurantId, name, description },
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setListOfMenus([...listOfMenus, response]);
        setLoading(false);
        setOpenMenuDialog(false); // Cerrar el diálogo al crear
      })
      .catch((error) => {
        console.log("Error al registrar el menú:", error);
        setLoading(false);
      });
  };

  // Mostrar un menú con sus secciones
  const showMenu = (menuId) => {
    setLoadingSectionMenu(true);
    makeRequest(
      "GET",
      `menu_manager/view/${menuId}`,
      {},
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setSections(response.sections || []);
        setMenuId(menuId);
        setSectionMenu(true);
        setLoadingSectionMenu(false);
      })
      .catch((error) => {
        console.log("Error al obtener las secciones del menú:", error);
        setLoadingSectionMenu(false);
      });
  };

  // Crear una nueva sección
  const createSection = (name) => {
    makeRequest(
      "POST",
      "menu_manager/edit_menu",
      {
        sections: [
          ...sections,
          { name, order: sections.length + 1, products: [] },
        ],
        is_active: true,
        restaurant_id: restaurantId,
        menu_id: menuId,
      },
      "application/json",
      localStorage.getItem("token"),
      localStorage.getItem("tokenType"),
      () => {}
    )
      .then((response) => {
        setSections(response.sections || []);
        setOpenSectionDialog(false);
      })
      .catch((error) => {
        console.log("Error al crear la sección:", error);
      });
  };

  // const showProducts = (sectionId) => {
  //   console.log("Mostrar productos de la sección:", sectionId);
  // };

  // Renderizado del componente principal
  return (
    <>
      {sectionProducts ? (
        <>
          {loadingSectionProducts ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  padding: "20px",
                }}
              >
                <SendButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{ maxWidth: "200px" }}
                >
                  Crear Nueva Sección
                </SendButton>
              </Box>
              <SectionsMenuBuild items={sections} onClickProducts={() => {}} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 4,
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{
                    clipPath: "circle(50% at 50% 50%)",
                    background:
                      "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </>
          )}
        </>
      ) : sectionMenu ? (
        <>
          {loadingSectionMenu ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                  padding: "20px",
                }}
              >
                <SendButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{ maxWidth: "200px" }}
                >
                  Crear Nueva Sección
                </SendButton>
              </Box>
              <SectionsMenuBuild items={sections} onClickProducts={() => {}} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 4,
                  width: "100%",
                }}
              >
                <IconButton
                  onClick={() => setOpenSectionDialog(true)}
                  sx={{
                    clipPath: "circle(50% at 50% 50%)",
                    background:
                      "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)",
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                padding: "20px",
                flexDirection: "column",
              }}
            >
              <SendButton
                onClick={() => setOpenMenuDialog(true)}
                sx={{ maxWidth: "200px" }}
              >
                Crear Nuevo Menú
              </SendButton>
              <MenuList listOfMenus={listOfMenus} showMenu={showMenu} />
            </Box>
          )}
        </>
      )}

      {/* Dialogo para crear un nuevo menú */}
      <CustomDialog
        open={openMenuDialog}
        onClose={() => setOpenMenuDialog(false)}
      >
        <CustomDialogTitle>Crear Menú</CustomDialogTitle>
        <CustomDialogContent>
          <CreateMenu
            name={name}
            description={description}
            setName={setName}
            setDescription={setDescription}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setOpenMenuDialog(false)}>
            Cerrar
          </CloseButton>
          <SendButton onClick={createMenu}>Crear</SendButton>
        </CustomDialogActions>
      </CustomDialog>

      {/* Dialogo para crear una nueva sección */}
      <CustomDialog
        open={openSectionDialog}
        onClose={() => setOpenSectionDialog(false)}
      >
        <CustomDialogTitle>Crear Nueva Sección</CustomDialogTitle>
        <CustomDialogContent>
          <CreateSection
            nameSection={nameSection}
            setNameSection={setNameSection}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <CloseButton onClick={() => setOpenSectionDialog(false)}>
            Cerrar
          </CloseButton>
          <SendButton onClick={() => createSection(nameSection)}>
            Crear
          </SendButton>
        </CustomDialogActions>
      </CustomDialog>
    </>
  );
}

export default MenuRestaurant;
