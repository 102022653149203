import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
import { Box, Typography } from "@mui/material";

function SectionsMenuBuild({ items, onClickProducts }) {
  // Ordenar los items según la llave 'order'
  const sortedItems = React.useMemo(() => {
    return [...items].sort((a, b) => a.order - b.order);
  }, [items]);

  const [currentItems, setCurrentItems] = React.useState(sortedItems);

  const onSortEnd = (oldIndex, newIndex) => {
    // Reordenar los items usando arrayMove
    const newItems = arrayMove(currentItems, oldIndex, newIndex);

    // Actualizar el valor de 'order' según la nueva posición (index + 1)
    const updatedItems = newItems.map((item, index) => ({
      ...item,
      order: index + 1, // Actualizamos la propiedad 'order'
    }));

    // Actualizar el estado con los items ordenados y con el nuevo valor de 'order'
    setCurrentItems(updatedItems);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Centrar los items horizontalmente
        width: "100%", // Asegurar que el contenedor principal ocupe todo el ancho
      }}
    >
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
        style={{
          width: "100%",
          maxWidth: "600px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }} // Centrar el SortableList
      >
        {currentItems.map((item, index) => (
          <SortableItem key={index}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                marginBottom: "20px", // Separar las secciones con 20px
                maxWidth: "100%", // Asegurar que el ancho máximo sea 100%
                width: "100%", // Asegurar que el ancho sea 100%
                transition: "background 0.3s ease", // Transición suave para el cambio de color
                "&:hover": {
                  background: "linear-gradient(92deg, #5A4E99 0%, #968AD5 100%)", // Color de fondo en hover
                  cursor: "pointer", // Cambiar el cursor al pasar el mouse
                },
                margin: "10px 0", // Separar cada item con 10px de margen vertical
              }}
              onClick={() => onClickProducts(item)} // Acción al hacer clic en el item
            >
              <Typography variant="h6">
                {index + 1}. {item.name}
              </Typography>
            </Box>
          </SortableItem>
        ))}
      </SortableList>
    </Box>
  );
}

export default SectionsMenuBuild;
