import React, { useState } from 'react';
import { errorMapping, makeRequest } from '../../../assets/functions_helper';
import ListData from '../../../components/listData';
import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  Snackbar,
  Typography,
} from '@mui/material';
import CreateRestaurant from './create_restaurant';
import { IconButtonAnimated } from '../../../components/resources';
import CloseIcon from '@mui/icons-material/Close';
import ListUsersRestaurants from './list_users_restaurant';
import ListTablesRestaurant from './list_tables_restaurant';
import { useNavigate } from 'react-router-dom';
import CustomTabs from '../../../components/customTabs';
import ArrowCircleLeft from '../../../assets/images/arrow_circle_left.png';
import {
  AddButtonStyled,
  AddButtonStyledText,
  CloseButton,
  SendButton,
} from '../../../components/buttons';
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogContent,
  CustomDialogTitle,
} from '../../../components/customDialog';
import MenuRestaurant from './menu_restaurant';

function ListRestaurants({
  restaurants,
  setRestaurants,
  total,
  setTotal,
  pages,
  setPages,
  currentPage,
  setCurrentPage,
  isLoaded,
  setIsLoaded,
}) {
  const [page, setPage] = useState(1); // Variable de estado para guardar la pagina actual
  const [open, setOpen] = useState(false); // Variable de estado para abrir y cerrar el dialogo
  const [restaurantName, setRestaurantName] = useState(''); // Variables de estado para guardar los valores de los inputs
  const [openSnackBar, setOpenSnackBar] = useState(false); // Variable de estado para abrir y cerrar el snackbar
  const [messageSnackBar, setMessageSnackBar] = useState(''); // Variable de estado para guardar el mensaje del snackbar
  const [openEdit, setOpenEdit] = useState(false); // Variable de estado para abrir y cerrar el dialogo de editar
  const [restaurantId, setRestaurantId] = useState(''); // Variable de estado para guardar el id del restaurante
  const [nameRestaurant, setNameRestaurant] = useState(''); // Variable de estado para guardar el nombre del restaurante
  const [openModalCode, setOpenModalCode] = useState(false); // Variable de estado para abrir y cerrar el dialogo de codigo del dia
  const [codeDay, setCodeDay] = useState(''); // Variable de estado para guardar el codigo del dia

  const tabs = [
    {
      label: 'Administradores',
      content: (
        <ListUsersRestaurants
          idRestaurant={`${restaurantId}`}
          role={[2]}
          title={``}
          titleContent={'Asigna un administrador al restaurante'}
          titleCreate={'Crear'}
          nameColumn1="Nombre"
          nameColumn2="Correo"
          nameColumn3="Acciones"
          nameOfRestaurant={nameRestaurant}
        />
      ),
    },
    {
      label: 'Meseros',
      content: (
        <ListUsersRestaurants
          idRestaurant={restaurantId}
          role={[3, 4]}
          title={``}
          titleContent={`Asigna un mesero al restaurante`}
          titleCreate={'Crear'}
          nameColumn1="Nombre"
          nameColumn2="Correo"
          nameColumn3="Acciones"
          nameOfRestaurant={nameRestaurant}
          waiters={true}
        />
      ),
    },
    {
      label: 'Mesas',
      content: (
        <ListTablesRestaurant
          idTable={restaurantId}
          title={``}
          titleContent={`Crea una mesa para el restaurante`}
          titleCreate={'Crear'}
          nameColumn1="Mesas"
          nameColumn3="Acciones"
          nameOfRestaurant={nameRestaurant}
        />
      ),
    },
    // Agrega aquí más pestañas si es necesario
    {
      label: 'Menu',
      content: <MenuRestaurant restaurantId={restaurantId}/>,
    },
  ];

  const navigate = useNavigate();

  const ChangePage = (value) => {
    let url;
    // Asumiendo que `rol` es el estado o prop que contiene el valor del rol actual
    if (localStorage.getItem('rol') === '2') {
      // Utiliza el endpoint específico para el rol 2
      url = `restaurant_admin/list_my_restaurants?page=${value}&size=5&order_by=id&order=desc`;
    } else {
      // Utiliza el otro endpoint para cualquier otro rol
      url = `restaurants/?page=${value}&size=5&order_by=id&order=desc`;
    }

    makeRequest(
      'GET',
      url,
      null,
      'application/json',
      localStorage.getItem('token'),
      localStorage.getItem('tokenType'),
      null,
      null,
      () => {
        navigate('/login');
      }
    )
      .then((response) => {
        console.log(response);
        const updateRestaurants = response.restaurants.map((restaurant) => ({
          ...restaurant,
          title: restaurant.name,
          title2: `${restaurant.name} Usuarios: ${restaurant.user_count}`,
        }));
        setRestaurants(updateRestaurants);
        setTotal(response.total);
        setPages(response.pages);
        setCurrentPage(response.currentPage);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.log('Error al obtener los usuarios');
      });
  };

  const handleClose = () => {
    setOpen(false);

    setRestaurantName('');
  };

  const handleSubmitCreateRestaurant = (e) => {
    // Validar campos vacios
    if (restaurantName === '') {
      setMessageSnackBar('Campos vacios');
      setOpenSnackBar(true);
      return;
    } else {
      // Hacer la peticion si nada esta vacio
      makeRequest(
        'POST',
        'restaurants/create_restaurant/',
        {
          name: restaurantName,
        },
        'application/json',
        localStorage.getItem('token'),
        localStorage.getItem('tokenType'),
        null,
        null,
        () => {
          navigate('/login');
        }
      )
        .then((response) => {
          console.log(response);
          handleClose();
        })
        .catch((err) => {
          console.log(err['error_code']);
          for (const error in errorMapping) {
            console.log(err['error_code']);
            if (error.includes(err['error_code'])) {
              setMessageSnackBar(errorMapping[err['error_code']]);
              setOpenSnackBar(true);
              return; // Salir de la función si se encuentra un código de error
            }
          }
        });
    }
  };

  const action = (
    <>
      <IconButtonAnimated
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setOpenSnackBar(false);
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButtonAnimated>
    </>
  );

  const getCodeDay = () => {
    makeRequest(
      'GET',
      `restaurant_admin/restaurant_code/${restaurantId}`,
      null,
      'application/json',
      localStorage.getItem('token'),
      localStorage.getItem('tokenType'),
      null,
      null,
      () => {
        navigate('/login');
      }
    )
      .then((response) => {
        setCodeDay(response.code);
        setOpenModalCode(true);
      })
      .catch((error) => {
        console.log('Error al obtener el codigo del dia');
      });
  };

  const shutDownOrTurnOn = (action) => {
    let url;
    if (action === 'shutDown') {
      url = `restaurant_admin/code/${restaurantId}?require_code=false`;
    } else {
      url = `restaurant_admin/code/${restaurantId}?require_code=true`;
    }

    makeRequest(
      'PUT',
      url,
      null,
      'application/json',
      localStorage.getItem('token'),
      localStorage.getItem('tokenType'),
      null,
      null,
      () => {
        navigate('/login');
      }
    )
      .then((response) => {
        if (action === 'shutDown') {
          setMessageSnackBar('Codigo apagado');
        } else {
          setMessageSnackBar('Codigo encendido');
          setCodeDay(response.restaurant_new_code);
        }
        setOpenSnackBar(true);
        return; // Salir de la función si se encuentra un código de error
      })
      .catch((error) => {
        console.log('Error al obtener el codigo del dia');
      });
  };
  return (
    <>
      {isLoaded ? (
        <>
          {!openEdit && (
            <ListData
              title="Restaurantes"
              data={restaurants}
              setData={setRestaurants}
              edit={true}
              editMessage="Editar Usuario"
              editFunction={(value, value2) => {
                setOpenEdit(true);
                setRestaurantId(value);
                setNameRestaurant(value2);
              }}
              page={page}
              setPage={setPage}
              changePage={ChangePage}
              pages={pages}
              total={total}
              currentPage={currentPage}
              create={localStorage.getItem('rol') === '2' ? false : true}
              createMessage="Crear Restaurante"
              createFunction={() => {
                setOpen(true);
              }}
              nameColumn1="Restaurante"
              nameColumn2="Usuarios"
              nameColumn3="Acciones"
            />
          )}

          <CustomDialog open={open} onClose={handleClose}>
            <CustomDialogTitle>Crear Restaurante</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText>
                Diligencia todos los campos para crear un restaurante
              </DialogContentText>

              <CreateRestaurant
                restaurantName={restaurantName}
                setRestaurantName={setRestaurantName}
              />
            </CustomDialogContent>

            <CustomDialogActions>
              <CloseButton onClick={handleClose}>Cerrar</CloseButton>
              <SendButton onClick={handleSubmitCreateRestaurant}>
                Enviar
              </SendButton>
            </CustomDialogActions>
          </CustomDialog>

          <CustomDialog
            open={openModalCode}
            onClose={() => setOpenModalCode(!openModalCode)}
          >
            <CustomDialogTitle>Tu codigo del Restaurante es</CustomDialogTitle>
            <CustomDialogContent>
              <DialogContentText>{codeDay}</DialogContentText>
            </CustomDialogContent>

            <CustomDialogActions>
              <Button onClick={() => setOpenModalCode(!openModalCode)}>
                Cerrar
              </Button>
              <Button onClick={() => shutDownOrTurnOn('shutDown')}>
                Apagar
              </Button>
              <Button onClick={() => shutDownOrTurnOn('turnOn')}>
                Encender
              </Button>
            </CustomDialogActions>
          </CustomDialog>

          {openEdit && (
            <Box
              sx={{
                padding: 1,
                display: 'flex',
                flexDirection: 'column',
                gap: 2.5, // Espacio entre elementos hijos
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 2.5,
                  paddingLeft: '25px',
                  paddingRight: '25px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <IconButtonAnimated
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => setOpenEdit(!openEdit)}
                  >
                    <img
                      src={ArrowCircleLeft}
                      alt="Notification"
                      style={{ width: '30px', height: '30px' }}
                    />
                  </IconButtonAnimated>
                  <Typography
                    variant="h3"
                    sx={{
                      color: 'white',
                      fontSize: 20,
                      fontFamily: 'Arial',
                      fontWeight: '400',
                      wordWrap: 'break-word',
                    }}
                  >
                    {nameRestaurant}
                  </Typography>
                </Box>

                <AddButtonStyled onClick={() => getCodeDay()}>
                  <AddButtonStyledText>Codigo Dia</AddButtonStyledText>
                </AddButtonStyled>
              </Box>

              <CustomTabs tabs={tabs} />
            </Box>
          )}

          <Snackbar
            open={openSnackBar}
            autoHideDuration={2000}
            handleClose={() => {
              setOpenSnackBar(false);
            }}
            message={messageSnackBar}
            action={action}
          />
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default ListRestaurants; // Exporta el componente ListUsersRestaurants
